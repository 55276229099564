import { Redirect } from '@reach/router'
import React, { FC, useEffect } from 'react'
import 'twin.macro'

import { ROUTES } from '../../components/layout/Navigation'
import { CaseType, OnboardingStep, useMatchOnboardingState } from './OnboardingStep'

export const CreatePage: FC<{ type: CaseType }> = ({ type }) => (
  <OnboardingStep
    step={1}
    desktopTitle={'1. Tell us about yourself'}
    imageUrl=''
  >
    <Start type={type} />
  </OnboardingStep>
)

const Start: FC<{ type: CaseType }> = ({ type }) => {
  const { actions } = useMatchOnboardingState()

  useEffect(
    () => {
      actions.update({ caseType: type })
    },
    [type]
  )

  return (
    <Redirect to={ROUTES.onboarding.step1} noThrow />
  )
}

